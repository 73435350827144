import { IPropChildren } from '@ucheba/utils/helpers/components/prop.types'
import { EModButtonColor } from '@ucheba/ui/components/Button/types'
import { ESpacing } from '@ucheba/utils/helpers/styles/variables'

export interface IRequestFormProps extends IPropChildren {
  title?: string
  description?: string
  buttonColor?: keyof typeof EModButtonColor
  spacing?: keyof typeof ESpacing
  funnel: string
  productType: string
  requestTarget: string | undefined
  onSubmitForm?: () => void
  productId?: number | undefined
}

export interface IPhoneConfirmDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (code: string) => void
  phone: string
  isLoading: boolean
}

export interface IMessengerDialogProps {
  open: boolean
  onClose: () => void
}

export enum ETypeRequest {
  admission_support = 'admission_support',
  ege_lead_free_lesson = 'ege_lead_free_lesson',
  ege_lead_free_test = 'ege_lead_free_test',
  ege_lead_consult = 'ege_lead_consult',
  ege_lead_request = 'ege_lead_request',
  oge_lead_request = 'oge_lead_request',
  proforientation_request = 'proforientation_request',
  select_institution_request = 'select_institution_request',
}
